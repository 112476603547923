import React, { useEffect, useRef, useState } from 'react'

import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Wrapper } from '@googlemaps/react-wrapper'
import { createCustomEqual } from 'fast-equals'
import { isLatLngLiteral } from '@googlemaps/typescript-guards'

import styles from './DialogoUrgencia.module.css'
import { recuperaUrgencia } from '../../../servicos/Api'
import { formatarCPF } from '../../../Utils/formatar'

const render = status => {
	return <h1>{status}</h1>
}

const Map = ({
	onClick,
	onIdle,
	children,
	...options
}) => {
	const ref = useRef(null)
	const [ map, setMap ] = useState()
  
	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}))
		}	
	}, [ ref, map ])
  
	useDeepCompareEffectForMaps(() => {
		if (map) {
			map.setOptions(options)
		}
	}, [ map, options ])
  
	useEffect(() => {
		if (map) {
			[ 'click', 'idle' ].forEach((eventName) =>
				// eslint-disable-next-line no-undef
				google.maps.event.clearListeners(map, eventName)
			)
  
			if (onClick) {
				map.addListener('click', onClick)
			}
  
			if (onIdle) {
				map.addListener('idle', () => onIdle(map))
			}
		}
	}, [ map, onClick, onIdle ])
  
	return (
		<>
			<div ref={ref} className={styles.mapa} />
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					return React.cloneElement(child, { map })
				}
			})}
		</>
	)
}
  
const Marker = options => {
	const [ marker, setMarker ] = useState()
  
	useEffect(() => {
		if (!marker) {
			// eslint-disable-next-line no-undef
			setMarker(new google.maps.Marker())
		}
  
		return () => {
			if (marker) {
				marker.setMap(null)
			}
		}
	}, [ marker ])
  
	useEffect(() => {
		if (marker) {
			marker.setOptions(options)
		}
	}, [ marker, options ])
  
	return null
}
  
const deepCompareEqualsForMaps = createCustomEqual(
	(deepEqual) => (a, b) => {
		if (
			isLatLngLiteral(a) ||
		// eslint-disable-next-line no-undef
		a instanceof google.maps.LatLng ||
		isLatLngLiteral(b) ||
		// eslint-disable-next-line no-undef
		b instanceof google.maps.LatLng
		) {
			// eslint-disable-next-line no-undef
			return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
		}
  
		return deepEqual(a, b)
	}
)
  
function useDeepCompareMemoize (value) {
	const ref = React.useRef()
  
	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value
	}
  
	return ref.current
}
  
function useDeepCompareEffectForMaps (
	callback,
	dependencies
) {
	React.useEffect(callback, dependencies.map(useDeepCompareMemoize))
}
  


export default function DialogoUrgencia (props) {
	const { aoFechar, abrir, id } = props
	const [ carregando, setCarregando ] = useState(false)
	const [ posicao, setPosicao ] = useState({ lat: 0, lng: 0 })
	const [ titulo, setTitulo ] = useState('')
	const zoom = 15

	const handleClose = () => {
		aoFechar()
	}

	useEffect(() => {
		if (!abrir) {
			return
		}
		setCarregando(true)
		recuperaUrgencia(id).then(({ data }) => {
			setPosicao({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) })
			setTitulo(`CPF: ${formatarCPF(data.cpf)}`)
			setCarregando(false)
		}) }, [ id ])

	return (
		<Dialog onClose={handleClose} open={abrir}>
			{ carregando && <CircularProgress/>}
			{ !carregando &&<>
				<DialogTitle>Localização da Urgência</DialogTitle>
				<DialogContent sx={{ minWidth: 500, minHeight: '100%' }}>
					<Wrapper apiKey={process.env.REACT_APP_MAPS_API_KEY} render={render}>
						<Map center={posicao} zoom={zoom}> 
							<Marker position={posicao} title={titulo}/>
						</Map>
					</Wrapper>
				</DialogContent> 
			</>}
		</Dialog> 
	)
}