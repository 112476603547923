import { React } from 'react'

import { 
	Button, 
	Dialog, 
	DialogContent, 
	DialogTitle, 
	DialogActions, 
	DialogContentText
} from '@mui/material'

export default function DialogoRegistroPm (props) {
	const { aoFechar, abrir, informacao } = props

	const fechar = () => {
		aoFechar()
	}

	return (
		<Dialog onClose={fechar} open={abrir}>
			<DialogTitle>Registro PM</DialogTitle>
			<DialogContent sx={{ minWidth: 400 }}>
				<DialogContentText>
					{informacao}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={fechar}>FECHAR</Button>
			</DialogActions>
		</Dialog>
	)
}
