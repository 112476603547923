import React from 'react'
import ReactDOM from 'react-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ptBR } from '@mui/material/locale'
import { BrowserRouter } from 'react-router-dom'

import './index.css'
import App from './App'

const theme = createTheme(
	{
		palette: {
			primary: { main: '#0057aa' },
		},
	},
	ptBR
)

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ThemeProvider>,
	document.getElementById('root')
)
