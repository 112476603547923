import { React, useState, useEffect } from 'react'

import { 
	Button, 
	TextField, 
	Dialog, 
	DialogContent, 
	DialogTitle, 
	DialogActions 
} from '@mui/material'

import { adicionaInformacoes } from '../../../servicos/Api'

export default function DialogoAdicionarInformacoes (props) {
	const { aoFechar, abrir, id, informacao } = props
	const [ valor, setValor ] = useState(informacao || '')
	const [ erro, setErro ] = useState(false)


	useEffect(() => {
		setValor(informacao || '')
		setErro(false)
	}, [ abrir ])

	const fechar = () => {
		aoFechar(null)
	}

	const salvar = () => {
		if (!valor) {
			setErro(true)
			return
		}
		adicionaInformacoes(id, valor).then(() => aoFechar('Informação adicionada com sucesso!'))
	}

	const alterarValor = (event) => {
		setErro(false)
		setValor(event.target.value)
	}

	return (
		<Dialog onClose={fechar} open={abrir}>
			<DialogTitle>Informações adicionais</DialogTitle>
			<DialogContent sx={{ minWidth: 400 }}>
				<TextField
					value={valor}
					error={erro}
					autoFocus
					margin="dense"
					label=""
					fullWidth
					variant="standard"
					helperText={ erro && 'Acrescente a informação ou clique em cancelar.'}
					onChange={alterarValor}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="error" onClick={fechar}>CANCELAR</Button>
				<Button onClick={salvar}>SALVAR</Button>
			</DialogActions>
		</Dialog>
	)
}
