import { format } from 'date-fns'

export function formatarCPF (cpf) {
	if (!cpf) {
		return cpf
	}
	return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d)/,'$1.$2.$3-$4')
}

export function formatarCEP (cep) {
	if (!cep) {
		return cep
	}
	return cep.replace(/^(\d{2})(\d{3})(\d)/,'$1.$2-$3')
}

export function formatarTelefone (telefone) {
	if (!telefone) {
		return telefone
	}
	return telefone.replace(/^(\d{2})(\d{2})(\d{5})(\d)/,'($2) $3-$4')
}

export function formatarData (data) {
	if (!data) {
		return data
	}
	return data.toISOString().substring(0,10)
}

export function formatarDataHora (data) {
	if (!data) {
		return data
	}
	return format(getUTCData(data), 'dd/MM/yyyy HH:mm')+'h'
}

function getUTCData (data) {
	const localData = new Date(data)
	return new Date(
		localData.getUTCFullYear(),
		localData.getUTCMonth(),
		localData.getUTCDate(),
		localData.getUTCHours(),
		localData.getUTCMinutes(),
		localData.getUTCSeconds()
	)

}