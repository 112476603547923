import React, { useState } from 'react'

import { Alert, Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router'

import { login } from '../../servicos/Autenticacao'
import styles from './Login.module.css'

export default function Login () {
	const [ cpf, setCpf ] = useState('')
	const [ cpfErro, setCpfErro ] = useState()

	const [ senha, setSenha ] = useState('')
	const [ senhaErro, setSenhaErro ] = useState()

	const [ carregando, setCarregando ] = useState(false)
	const [ erro, setErro ] = useState()

	const navigate = useNavigate()
	
	const alteraCpf = event => {
		setCpf(event.target.value)
		setErro(null)
		
		if (!event.target.value) {
			setCpfErro(true)
			return
		}
		
		setCpfErro(false)
	}

	const alteraSenha = event => {
		setSenha(event.target.value)
		setErro(null)
		validaSenha(event)
	}
    
	const validaSenha = event => {
		if (!event.target.value) {
			setSenhaErro(true)
			return
		}
		
		setSenhaErro(false)
	}
    
	const autenticar = () => {
		if (!cpf || !senha) {
			setSenhaErro(!senha)
			setCpfErro(!cpf)
			return
		} 
		setCarregando(true)
		login(cpf.replace(/[^\d]+/g, ''), senha).then(() => {
			setCarregando(false)
			navigate('/')			
		}).catch(erro => {
			setErro(erro.message)
			setCarregando(false)
		})
	}
	return <>
		<div className={styles.telaCheia}> 
			<div className={styles.fundo}> </div>
		</div>
		<div className={styles.telaCheia}> 
			<div className={styles.figuras}>
				<div className={styles.figuraEsquerda}></div>
				<div className={styles.figuraDireita}></div>
			</div>
		</div>
		<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: 'primary.main' }}>
		
			<Paper elevation={3} className={styles.caixaLogin}>
				<Paper elevation={3} sx={{ width: 250, mt: -7, p: 3, bgcolor: 'primary.main', color: 'white', mb: 2 }}>
					<Typography variant="h6" lineHeight={0.5}>SMDSTH</Typography>
				</Paper>
				{ erro && <Alert severity="error">{erro}</Alert> }
				<InputMask
					mask="999.999.999-99"
					value={cpf}
					onChange={alteraCpf}
				>
					{() => <TextField 
						error={cpfErro}
						autoFocus
						margin="dense"
						label="CPF"
						fullWidth
						variant="standard"
						helperText={ cpfErro && 'CPF Obrigatório'}
					/>}
				</InputMask>
				<TextField 
					value={senha}
					onChange={alteraSenha}
					onBlur={validaSenha}
					onKeyDown={event => event.key == 'Enter' && autenticar()}
					error={senhaErro}
					margin="dense"
					label="Senha"
					type="password"
					fullWidth
					variant="standard"
					helperText={ senhaErro && 'Senha Obrigatório'}
				/>
				{ !carregando && <Button variant="contained" sx={{ mt:2, width: 250 }} onClick={autenticar} >ENTRAR</Button> }
				{ carregando && <Button variant="contained" disabled sx={{ mt:2, width: 250, backgroundColor: blue[100] }} ><CircularProgress /></Button> }				
			</Paper>
		</Box>
	</>
}