import React from 'react'

import { Route, Routes } from 'react-router-dom'

import './App.css'
import BarraNavegacao from './componentes/BarraNavegacao'
import Denuncias from './paginas/Denuncias'
import Login from './paginas/Login'

const ContainerPadrao = () => {
	return <>
		<BarraNavegacao/>
		<Routes>
			<Route  path="/" element={<Denuncias />} />
		</Routes>
	</>
}

function App () {
	return (
		<div className="App">
			<Routes>
				<Route  path="/login" element={<Login />} />
				<Route  path="*" element={<ContainerPadrao />} />
			</Routes>
		</div>
	)
}

export default App
