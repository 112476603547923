import { React, useEffect, useState } from 'react'

import { CircularProgress, Dialog, DialogContent, DialogTitle, Snackbar, Typography } from '@mui/material'

import * as Api from '../../../servicos/Api'
import { formatarCEP, formatarCPF, formatarDataHora, formatarTelefone } from '../../../Utils/formatar'

export default function DialogoDenuncia (props) {
	const { aoFechar, abrir, id } = props
	const [ dados, setDados ] = useState()
	const [ mensagemSnackbar, setMensagemSnackbar ] = useState(null)
	const [ carregando, setCarregando ] = useState(false)

	useEffect(() => {
		if (!abrir) {
			return
		}
		setCarregando(true)
		Api.recuperaDenuncia(id).then(({ data }) => {
			setDados(data)
			setCarregando(false)
		})
	}, [ id ])
  
	const handleClose = () => {
		aoFechar()
	}

	const baixarArquivo = (anexo) => Api.downloadArquivo(anexo).catch(() => setMensagemSnackbar('Não foi possível realizar o download do arquivo! Tente novamente mais tarde'))
  
	return (<>
		<Dialog onClose={handleClose} open={abrir}>
			<DialogTitle>Detalhamento da Denúncia</DialogTitle>
			{carregando && <CircularProgress />}
			{dados && !carregando && <DialogContent sx={{ minWidth: 400 }}>
				<div> <Typography variant="subtitle2" component="span">Violência ocorrendo:</Typography> {dados.violenciaOcorrendo} </div>
				<div> <Typography variant="subtitle2" component="span">Tipo:</Typography> {dados.tipo}</div>
				<div> <Typography variant="subtitle2" component="span">Demandante:</Typography> {dados.demandante}</div>
				<div> <Typography variant="subtitle2" component="span">Vítima:</Typography> {dados.vitima}</div>
				<div> <Typography variant="subtitle2" component="span">Idade:</Typography> {dados.idade}</div>
				<div> <Typography variant="subtitle2" component="span">Agressor:</Typography> {dados.agressor}</div>
				<div> <Typography variant="subtitle2" component="span">Raça/Cor:</Typography> {dados.racaCor}</div>
				<div> <Typography variant="subtitle2" component="span">End.:</Typography> {dados.endereco}</div>
				<div> <Typography variant="subtitle2" component="span">Nro:</Typography> {dados.numero}</div>
				<div> <Typography variant="subtitle2" component="span">Compl:</Typography> {dados.complemento}</div>
				<div> <Typography variant="subtitle2" component="span">Bairro:</Typography> {dados.bairro}</div>
				<div> <Typography variant="subtitle2" component="span">CEP:</Typography> {formatarCEP(dados.cep)}</div>
				<div> <Typography variant="subtitle2" component="span">Reincidência:</Typography> {dados.reincidencia}</div>
				<div> <Typography variant="subtitle2" component="span">Obs:</Typography> {dados.observacao}</div>
				<div> <Typography variant="subtitle2" component="span">CPF usuário:</Typography> {formatarCPF(dados.cpf)}</div>
				<div> <Typography variant="subtitle2" component="span">Telefone usuário:</Typography> {formatarTelefone(dados.telefone)}</div>
				<div> <Typography variant="subtitle2" component="span">Data:</Typography> {formatarDataHora(dados.data)}</div>
				{ dados && dados.anexos.length > 0 && <Typography variant="subtitle2" component="div">Anexos:</Typography> }
				{ dados && dados.anexos && dados.anexos.map((anexo, i) => <div key={i}><a href="#" onClick={() => baixarArquivo(anexo)}>{anexo.nome}</a></div>)}
				
			</DialogContent>}
		</Dialog>
		<Snackbar 
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			autoHideDuration={4000}
			open={mensagemSnackbar !== null && mensagemSnackbar !== ''}
			message={mensagemSnackbar}
			onClose={() => setMensagemSnackbar(null)} />
	</>
	)
}