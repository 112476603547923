import React, { useEffect, useState } from 'react'

import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import localePtBR from 'date-fns/locale/pt-BR'

export default function InputData ({ value, setValue, label, erro }) {
	const [ data, setData ] = useState(value)

	useEffect(() => {
		setData(value)
	}, [ value ])


	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={localePtBR}>
			<DatePicker
				label={label}
				value={data}
				onChange={(newValue) => setValue(newValue)}
				renderInput={(params) => <TextField {...params} error={ erro } size='small' />}
			/>
		</LocalizationProvider>
	)
}