import { React, useEffect, useState } from 'react'

import {
	Box,
	Button,
	Chip,
	CircularProgress,
	IconButton,
	Paper,
	Snackbar,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Tabs,
	Typography,
	useTheme,
} from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import LastPageIcon from '@mui/icons-material/LastPage'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { grey, red } from '@mui/material/colors'

import DialogoAdicionarInformacoes from './DialogoAdicionarInformacoes'
import DialogoDenuncia from './DialogoDenuncia'
import DialogoEncaminhar from './DialogoEncaminhar'
import FiltroData from '../../componentes/FiltroData'
import styles from './Denuncias.module.css'
import * as Api from '../../servicos/Api'
import DialogoRegistroPm from './DialogoRegistroPm'
import { formatarCPF, formatarTelefone, formatarDataHora } from '../../Utils/formatar'
import DialogoUrgencia from './DialogoUrgencia'

function AcoesPaginacaoTabela ({ count, page, rowsPerPage, onPageChange }) {
	const theme = useTheme()
	
	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0)
	}

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1)
	}

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1)
	}

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="primeira pág."
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="anterior"
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="próximo"
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="última pág."
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	)
}

export default function Denuncias () {
	const [ registros, setRegistros ] = useState(null)
	const [ registroSelecionado, setRegistroSelecionado ] = useState(null)
	const [ intervalo, setIntervalo ] = useState()
	const [ pagina, setPagina ] = useState(0)
	const [ tamanho, setTamanho ] = useState(5)
	const [ dialogoDetalhamento, setDialogoDetalhamento ] = useState(false)
	const [ dialogoUrgencia, setDialogoUrgencia ] = useState(false)
	const [ dialogoAdicionarInformacoes, setDialogoAdicionarInformacoes ] = useState(false)
	const [ dialogoEncaminhar, setDialogoEncaminhar ] = useState(false)
	const [ dialogoRegistroPm, setDialogoRegistroPm ] = useState(false)
	const [ aba, setAba ] = useState(0)
	const [ mensagemSnackbar, setMensagemSnackbar ] = useState(null)
	const [ carregando, setCarregando ] = useState(false)
	const [ cont, setCont ] = useState(0)
	const [ total, setTotal ] = useState(0)

	useEffect(() => {
		const intervaloId = setInterval(() => {
			Api.recuperaRecebidas(1, 0, null)
				.then(({ data }) => {
					if (data.totalElements > total) {
						setCont(cont + 1)
						setPagina(0)
						setAba(0)
						setIntervalo(null)
					}
				})
		}, 30000)
		return () => clearInterval(intervaloId)
	},[ total ])

	useEffect(() => {
		setCarregando(true)
				
		if (aba === 0) {
			Api.recuperaRecebidas(tamanho, pagina, intervalo)
				.then(({ data }) => {
					setRegistros(data)
					setCarregando(false)
					if (!intervalo) {
						setTotal(data.totalElements)
					}
				}).catch(() => setCarregando(false))
			return
		}

		Api.recuperaEncaminhadas(tamanho, pagina, intervalo)
			.then(({ data }) => {
				setRegistros(data)
				setCarregando(false)
			}).catch(() => setCarregando(false))


	}, [ tamanho, pagina, intervalo, aba, cont ])
	
	
	const aoTrocarPagina = (_, pagina) => {
		setPagina(pagina)
	}

	const aoTrocarTamanho = event => {
		setTamanho(parseInt(event.target.value, 10))
		setPagina(0)
	}

	const trocarAbas = (_, aba) => {
		setAba(aba)
		setPagina(0)
	}		

	const abrirDetalhamento = registro => {
		setRegistroSelecionado(registro)
		if (registro.tipo === 'Pânico') {
			setDialogoUrgencia(true)
			return 
		}
		setDialogoDetalhamento(true)
	}
	const fecharDetalhamento = () => setDialogoDetalhamento(false)
	const fecharUrgencia = () => setDialogoUrgencia(false)

	const abrirAdicionarInformacoes = registro => {
		setRegistroSelecionado(registro)
		setDialogoAdicionarInformacoes(true)
	}
	const fecharAdicionarInformacoes = messagemSnackbar => {
		if (messagemSnackbar) {
			setMensagemSnackbar(messagemSnackbar)
			setCont(cont + 1)
		}
		setDialogoAdicionarInformacoes(false)
	}
	
	const abrirEncaminhar = registro => {
		setRegistroSelecionado(registro)
		setDialogoEncaminhar(true)
	}

		
	const fecharEncaminhar = messagemSnackbar => {
		if (messagemSnackbar) {
			setMensagemSnackbar(messagemSnackbar)
		}
		setDialogoEncaminhar(false)
		setCont(cont + 1)
	}
		
	const abrirRegistroPm = registro => {
		setRegistroSelecionado(registro)
		setDialogoRegistroPm(true)
	}

	const fecharRegistroPm = () => {
		setDialogoRegistroPm(false)
	}

	return (
		<Box m={6}>
			<FiltroData valor={intervalo} aoFiltrar={intervalo => setIntervalo(intervalo)}></FiltroData>
			<Tabs value={aba} onChange={trocarAbas} aria-label="abas">
				<Tab label="RECEBIDAS" />
				<Tab label="ENCAMINHADAS" />
			</Tabs>
			<TableContainer sx={{ mt: 6 }} component={Paper}>
				<Table sx={{ minWidth: 500 }} aria-label="denúncias">
					<TableHead>
						<TableRow className={styles.barraTitulo}>
							<TableCell className={styles.titulo} align="center">
                TIPO
							</TableCell>
							<TableCell className={styles.titulo} align="center">
                ENTRADA
							</TableCell>
							<TableCell className={styles.titulo} align="center" sx={{ width: 300 }}>
                USUÁRIO
							</TableCell>
							<TableCell className={styles.titulo} align="center" sx={{ width: 180 }}>
                SITUAÇÃO
							</TableCell>
							<TableCell className={styles.titulo} align="center">
                FORMA VIOLÊNCIA
							</TableCell>
							<TableCell className={styles.titulo} align="center">
                DETALHAMENTO
							</TableCell>
							<TableCell className={styles.titulo} align="center">
                REGISTRO PM
							</TableCell>
							<TableCell className={styles.titulo} align="center">
                ADICIONAR INFORMAÇÕES
							</TableCell>
							{aba === 0 && <TableCell className={styles.titulo} align="center">
                ENCAMINHAR
							</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{carregando && <TableRow>
							<TableCell colSpan={9} align="center" component="th" scope="row"><CircularProgress /></TableCell>
						</TableRow>}
						{!carregando && registros && registros.content.length === 0 && <TableRow>
							<TableCell colSpan={9} align="center" component="th" scope="row">Não foram encontrados Registros</TableCell>
						</TableRow>}
						{!carregando && registros && registros.content.length > 0 && registros.content.map((registro) => (
							<TableRow key={registro.id}>
								<TableCell align="center" component="th" scope="row">
									<strong>{registro.tipo}</strong>
								</TableCell>
								<TableCell align="center">{formatarDataHora(registro.entrada)}</TableCell>
								<TableCell align="center" sx={{ width: 300 }}>
									<div>
										{ registro.tipo === 'Denúncia' && <div>{registro.usuario}</div> }
										{ registro.tipo === 'Pânico' && <div>CPF: {formatarCPF(registro.cpf)}</div> }
										{ registro.telefone && <Typography variant="caption" component="div" sx={{ color: grey[500] }}>Telefone: {formatarTelefone(registro.telefone)}</Typography>}
									</div>
								</TableCell>
								<TableCell align="center" sx={{ width: 180 }} >
									{registro.idViolenciaAcontecendo === 'S' && <Chip color="error" size="small" sx={{ backgroundColor: red[50], color: red[700], fontWeight: 600 }} label="Violência Acontecendo" />}
								</TableCell>
								<TableCell align="center">{registro.forma}</TableCell>
								<TableCell align="center">
									<Button variant="outlined" onClick={() => abrirDetalhamento(registro)}>
										<AddIcon></AddIcon>
									</Button>
								</TableCell>
								<TableCell align="center">
									<Button variant="outlined" onClick={() => abrirRegistroPm(registro)}>
										<InsertDriveFileIcon></InsertDriveFileIcon>
									</Button>
								</TableCell>
								<TableCell align="center">
									<Button variant="outlined"  onClick={() => abrirAdicionarInformacoes(registro)}>
										<EditIcon></EditIcon>
									</Button>
								</TableCell>
								{aba === 0 && <TableCell align="center">
									<Button variant="outlined" onClick={() => abrirEncaminhar(registro)}>
										<ArrowForwardIcon></ArrowForwardIcon>
									</Button>
								</TableCell> }
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[ 5, 10, 25 ]}
								colSpan={9}
								count={registros ? registros.totalElements: 0}
								rowsPerPage={tamanho}
								page={pagina}
								SelectProps={{
									inputProps: {
										'aria-label': 'Linhas por página',
									},
									native: true,
								}}
								onPageChange={aoTrocarPagina}
								onRowsPerPageChange={aoTrocarTamanho}
								ActionsComponent={AcoesPaginacaoTabela}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			{ registroSelecionado && <>
				<DialogoDenuncia
					id={registroSelecionado.oidRegistros}
					abrir={dialogoDetalhamento}
					aoFechar={fecharDetalhamento}
				/>
				<DialogoUrgencia
					id={registroSelecionado.oidRegistros}
					abrir={dialogoUrgencia}
					aoFechar={fecharUrgencia}
				/>
				<DialogoAdicionarInformacoes
					id={registroSelecionado.id}
					abrir={dialogoAdicionarInformacoes}
					informacao={registroSelecionado.informacoesAdicionais}
					aoFechar={fecharAdicionarInformacoes}
				/>
				<DialogoEncaminhar
					id={registroSelecionado.id}
					abrir={dialogoEncaminhar}
					aoFechar={fecharEncaminhar}
				/> 
				<DialogoRegistroPm 
					informacao={registroSelecionado.informacaoPm}
					abrir={dialogoRegistroPm}
					aoFechar={fecharRegistroPm}
				/>
			</>}
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				autoHideDuration={4000}
				open={mensagemSnackbar !== null && mensagemSnackbar !== ''}
				message={mensagemSnackbar}
				onClose={() => setMensagemSnackbar(null)}
			/>
		</Box>
	)
}
