import React, { useState } from 'react'

import { 
	AppBar, 
	Box, 
	Button, 
	Toolbar, 
	Typography, 
	Menu, 
	Container, 
	Avatar, 
	Tooltip, 
	MenuItem, 
	ListItemIcon,
	ListItemText
} from '@mui/material'
import { blue } from '@mui/material/colors'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import styles from './BarraNavegacao.module.css'

const BarraNavegacao = () => {
	const [ anchorElUsuario, setAnchorElUsuario ] = useState(null)

	const navigate = useNavigate()
	
	const abrirMenuUsuario = (event) => {
		setAnchorElUsuario(event.currentTarget)
	}

	const fecharMenuUsuario = () => {
		setAnchorElUsuario(null)
	}

	const sairAplicacao = () => {
		fecharMenuUsuario()
		localStorage.removeItem('token')
		localStorage.removeItem('usuario')
		navigate('/login')
	}

	return (
		<AppBar position="static">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Box sx={{ flexGrow: 1, cursor:'pointer', alignItems:'center', display: { xs: 'flex', md: 'flex' } }} onClick={() => navigate('/')}>
						<div className={styles.logo}></div>
						<Typography
							variant="h6"
							noWrap
							component="div"
							
						>
            SMDSTH
						</Typography>
					</Box>
					<Box sx={{ flexGrow: 0 }}>
						<Tooltip title="Opções do usuário">
							<Box sx={{ flexGrow: 0 }}>
								<Button variant="contained" disableElevation onClick={abrirMenuUsuario} sx={{ pl: 2, pr: 2 }}>
									<Avatar sx={{ bgcolor: blue[100], color: blue[600], mr: 2 }} />
									{localStorage.getItem('usuario')}
								</Button>
							</Box>
						</Tooltip>
						<Menu
							sx={{ mt: '45px', width: 320, maxWidth: '100%' }}
							id="menu-appbar"
							anchorEl={anchorElUsuario}
							anchorOrigin={{
								vertical: 'center',
								horizontal: 'center',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'center',
								horizontal: 'center',
							}}
							PaperProps={{  
								style: {  
									width: 350 
								},  
							}} 
							open={Boolean(anchorElUsuario)}
							onClose={fecharMenuUsuario}
						>
							<MenuItem onClick={sairAplicacao}>
								<ListItemText>Logout</ListItemText>
								<ListItemIcon>
									<Logout fontSize="small" />
								</ListItemIcon>
							</MenuItem>              
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	)
}
export default BarraNavegacao