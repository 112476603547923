import axios from 'axios'

const baseURL = process.env.REACT_APP_API_EAUTORIZA
const oidSistemas = process.env.REACT_APP_OID_SISTEMAS

const client = axios.create({
	baseURL,
})

export const login = (username, password) => {
	return client.post('/login-ativo', { oidSistemas, username, password }).then(({ data }) => {
		return new Promise((resolve, reject) => {

			if (data.sucesso === false) {
				reject(new Error(data.mensagem))
				return
			}

			if (!data.informacoesUsuario || data.informacoesUsuario.menus.filter(m => m.nmMenu == 'users').length === 0) {
				reject(new Error('Usuário não possui acesso na aplicação!'))
				return
			}

			localStorage.setItem('token', data.token)
			localStorage.setItem('usuario', data.informacoesUsuario.nmUsuario)
			resolve()		  
		})
		
	})
}