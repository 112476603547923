import React, { useEffect, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { red } from '@mui/material/colors'

import InputData from '../InputData'

export default function FiltroData ({ valor, aoFiltrar }) {

	const [ inicio, setInicio ] = useState(null)
	const [ fim, setFim ] = useState(null)
	const [ mensagem, setMensagem ] = useState(null)

	useEffect(() => {
		if (valor) {
			setInicio(valor.inicio)
			setFim(valor.fim)
			return
		}

		setInicio(null)
		setFim(null)

	},[ valor ])

	const limpar = () => {
		setInicio(null)
		setFim(null)
		setMensagem(null)
		aoFiltrar(null)
	}

	const filtrar = () => {
		if (!inicio && !fim) {
			setMensagem('Para filtrar por data, pelo menos um dos campos é obrigatório')
			return
		}
		if (inicio == 'Invalid Date') {
			setMensagem('Data de início está inválida')
			return
		}
		if (fim == 'Invalid Date') {
			setMensagem('Data de fim está inválida')
			return
		}
		if (inicio && fim && fim < inicio) {
			setMensagem('Data inicial maior do que a final')
			return
		}
		setMensagem(null)
		aoFiltrar({
			inicio,
			fim
		})
	}
    
	return <>
		<Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '550px', alignItems: 'center' }}>
				<InputData erro={!!mensagem} label="Início" value={inicio} setValue={setInicio} ></InputData>
				<Typography> à </Typography>
				<InputData erro={!!mensagem} label="Fim" value={fim} setValue={setFim}></InputData>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '200px', pl: 2 }}>
				<Button variant="contained" onClick={filtrar} >Filtrar</Button>
				<Button variant="contained" onClick={limpar}>Limpar</Button>
			</Box>
		</Box>
		{ mensagem && <Typography variant='caption' sx={{ color: red[500] }}>{mensagem}</Typography> }
	</>
}