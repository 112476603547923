import axios from 'axios'
import { formatarData } from '../Utils/formatar'
import useMock from './useMock'

const mockRequests = process.env.REACT_APP_MOCK_REQUESTS
const baseURL = process.env.REACT_APP_API

const client = axios.create({
	baseURL,
})

if (mockRequests === true) {
	useMock(client)
}

client.interceptors.request.use(request => {
	const token = localStorage.getItem('token')
	if (token) {
		request.headers['X-Session-Data'] = token
	}
	return request
})

client.interceptors.response.use(null, error => {
	if ([ 401, 403 ].includes(error.response.status)) {
		localStorage.removeItem('token')
		window.location='/login'
	}
	return Promise.reject(error)
})

export const recuperaRecebidas = (tamanho, pagina, intervalo) => {
	return recuperaRegistros('N', tamanho, pagina, intervalo)
}

export const recuperaEncaminhadas = (tamanho, pagina, intervalo) => {
	return recuperaRegistros('E', tamanho, pagina, intervalo)
}

export const recuperaRegistros = (tipo, tamanho, pagina, intervalo) => {
	let url = `/registros?tipo=${tipo}&tamanho=${tamanho}&pagina=${pagina}`
	if (intervalo && intervalo.inicio) {
		url += `&inicio=${formatarData(intervalo.inicio)}`
	}
	if (intervalo && intervalo.fim) {
		url += `&fim=${formatarData(intervalo.fim)}`
	}
	return client.get(url)
}

export const recuperaDenuncia = (id) => {
	return client.get(`/denuncias/${id}`)        
}

export const recuperaUrgencia = (id) => {
	return client.get(`/urgencias/${id}`)
}


export const encaminhar = id => {
	return atualizarRegistro(id, { situacaoRegistro: 'E' })
}

export const adicionaInformacoes = (id, informacoesAdicionais) => {
	return atualizarRegistro(id, { informacoesAdicionais })
}

export const atualizarRegistro = (id, atualizacao) => {
	return client.put(`/registros/${id}`, atualizacao)
}

export const downloadArquivo = ({ id, nome }) => {
	return client.get(`/anexos/${id}`)
		.then(({ data }) => {

			let a = document.createElement('a')
			a.setAttribute('href', 'data:text/plain;charset=ISO-8859-1,' + encodeURIComponent(data))
			a.setAttribute('download', nome)
			a.style.display = 'none'
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)  
		})      
}