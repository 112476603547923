import React, { useState } from 'react'
import Button from '@mui/material/Button'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Snackbar,
} from '@mui/material'

import { encaminhar } from '../../../servicos/Api'

export default function DialogoEncaminhar (props) {
	const { aoFechar, abrir, id } = props
	const [ mensagemSnackbar, setMensagemSnackbar ] = useState(null)

	const fechar = () => {
		aoFechar()
	}

	const salvar = () => {
		encaminhar(id).then(() => {
			aoFechar('Registro encaminhado com sucesso!')
		}).catch(erro => setMensagemSnackbar(erro.message))		
	}

	return (
		<>
			<Dialog onClose={fechar} open={abrir}>
				<DialogTitle>Encaminhar</DialogTitle>
				<DialogContent>
					<DialogContentText>
          Tem certeza que gostaria de encaminhar este registro?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="error" onClick={fechar}>
          NÃO
					</Button>
					<Button onClick={salvar}>SIM</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				autoHideDuration={4000}
				open={mensagemSnackbar !== null && mensagemSnackbar !== ''}
				message={mensagemSnackbar}
				onClose={() => setMensagemSnackbar(null)}
			/>
		</>
	)
}
